<template>
  <div>
    <p class="mb-3">
      If a solution contains
      <stemble-latex :content="'$5.00\\times 10^{-5}\\ \\text{M}$'" />
      each of
      <chemical-latex content="Ag^+," />
      <chemical-latex content="SO4^{2-}," />
      <chemical-latex content="Cl^-," />
      and
      <chemical-latex content="CO3^{2-}," />
      which of the following solids would precipitate from the solution?
    </p>

    <table class="table table-sm" style="width: 300px">
      <tbody>
        <tr>
          <td style="vertical-align: middle; height: 60px">
            <chemical-latex content="Ag2SO4" />
          </td>
          <td style="vertical-align: middle; height: 60px">
            <stemble-latex :content="'$\\text{K}_{\\text{sp}}=1.12\\times 10^{-5}$'" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle; height: 60px">
            <chemical-latex content="Ag2CO3" />
          </td>
          <td style="vertical-align: middle; height: 60px">
            <stemble-latex :content="'$\\text{K}_{\\text{sp}}=8.46\\times 10^{-12}$'" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle; height: 60px">
            <chemical-latex content="AgCl" />
          </td>
          <td style="vertical-align: middle; height: 60px">
            <stemble-latex :content="'$\\text{K}_{\\text{sp}}=1.77\\times 10^{-10}$'" />
          </td>
        </tr>
      </tbody>
    </table>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.precipitate" :disabled="!allowEditing">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question129',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        precipitate: null,
      },
      options: [
        {expression: '$\\ce{Ag2SO4}$', value: '1'},
        {expression: '$\\ce{AgCO3}$', value: '2'},
        {expression: '$\\ce{AgCl}$', value: '3'},
      ],
    };
  },
};
</script>
